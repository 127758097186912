import { applyFavIconAndTitle, sharedEnvironment } from './environment.common';

const RAILS_PROTO = 'https';
const API_HOST = `testing.platform161.com`;
const RAILS_FULLHOST = `${RAILS_PROTO}://${API_HOST}`;

const SAFE_CREATIVES_PROTO = RAILS_PROTO;
const SAFE_CREATIVES_HOST = `testing.creative-serving.org`;
const SAFE_CREATIVES_FULLHOST = `${SAFE_CREATIVES_PROTO}://${SAFE_CREATIVES_HOST}`;
const DOOH_URL = 'https://doohplanner.dev161.com/';

export const environment = {
    ...sharedEnvironment,
    production: false,
    env: 'testing',
    api: {
        host: `${RAILS_FULLHOST}`,
        base: `${RAILS_FULLHOST}/api/v3`,
        internal: `${RAILS_FULLHOST}/api/internal`,
        safeCreatives: `${SAFE_CREATIVES_FULLHOST}/api/internal`,
        refresh: 'tokens/refresh',
        login: 'tokens'
    },
    doohPlannerUrl: DOOH_URL
};
applyFavIconAndTitle();
