/* tslint:disable:variable-name */
import { Advertiser } from '../advertiser/advertiser.entity';
import { Browser } from '../browser/browser.service';
import { CampaignConversionPixel } from '../campaign-conversion-pixel/campaign-conversion-pixel.service';
import {
    CampaignInternetServiceProvider
} from '../campaign-internet-service-provider/campaign-internet-service-provider.service';
import { City } from '../city/city.service';
import { ContentCategory } from '../content-category/content-category.service';
import { ConversionPixel } from '../conversion-pixel/conversion-pixel.service';
import { Country } from '../country/country.entity';
import { Creative } from '../creative/creative.service';
import { Device } from '../device/device.service';
import { DeviceType } from '../device-type/device-type.service';
import { DirectDeal } from '../direct-deal/direct-deal.service';
import { Domain } from '../domain/domain.service';
import { DomainSet } from '../domain-set/domain-set.service';
import { Field, BelongsTo, BaseEntity, HasMany, DefaultTransforms } from '../common/meta';
import { GoogleVertical } from '../google-vertical/google-vertical.service';
import { IAdvertiser } from '../advertiser/interfaces';
import { IBrowser } from '../browser/interfaces';
import {
    ICampaign, Filter, CustomUserRating, LatLongTargeting, ConnectionTypeTargeting,
    VideoVisibility, DisplayVisibility, AdsTxt, SupplyType, VideoPlacementTargeting, VideoAdFormat, Phases,
    CampaignConversionPixelAttribute, ConversionEvents, Attention
} from './interfaces';
import { ICampaignConversionPixel } from '../campaign-conversion-pixel/interfaces';
import { ICity } from '../city/interfaces';
import { IContentCategory } from '../content-category/interfaces';
import { IConversionPixel } from '../conversion-pixel/interfaces';
import { ICampaignsDirectDeal } from '../campaigns-direct-deal/interfaces';
import { ICampaignInternetServiceProvider } from '../campaign-internet-service-provider/interfaces';
import { ICountry } from '../country/interfaces';
import { ICreative } from '../creative/interfaces';
import { IDevice } from '../device/interfaces';
import { IDeviceType } from '../device-type/interfaces';
import { IDesignatedMarketArea } from '../designated-market-area/interfaces';
import { IDirectDeal } from '../direct-deal/interfaces';
import { IDomain } from '../domain/interfaces';
import { IDomainSet } from '../domain-set/interfaces';
import { IGoogleVertical } from '../google-vertical/interfaces';
import { IIabCategory } from '../iab-category/interfaces';
import { ILanguage } from '../language/interfaces';
import { IMediaPlan } from '../media-plan/interfaces';
import { IMobileApp } from '../mobile-app/interfaces';
import { IMobileCarrier } from '../mobile-carrier/interfaces';
import { IRegion } from '../region/interfaces';
import { IRtbProduct } from '../rtb-product/interfaces';
import { IRtbUrl } from '../rtb-url/interfaces';
import { ISection } from '../section/interfaces';
import { ITargetingSet } from '../targeting-set/interfaces';
import { IabCategory } from '../iab-category/iab-category.service';
import { InventorySource } from '../inventory-source/inventory-source.service';
import { CampaignsDirectDeal } from '../campaigns-direct-deal/campaigns-direct-deal.service';
import { Language } from '../language/language.service';
import { MediaPlan } from '../media-plan/media-plan.entity';
import { MobileApp } from '../mobile-app/mobile-app.service';
import { MobileCarrier } from '../mobile-carrier/mobile-carrier.service';
import { OperatingSystem } from '../operating-system/operating-system.service';
import { Region } from '../region/region.entity';
import { RtbProduct } from '../rtb-product/rtb-product.service';
import { RtbUrl } from '../rtb-url/rtb-url.service';
import { Section } from '../section/section.entity';
import { TargetingSet } from '../targeting-set/targeting-set.entity';
import { DesignatedMarketArea } from '../designated-market-area/designated-market-area.service';
import { IResource } from '../common/interfaces';
import { ICampaignInventorySource } from './campaign-inventory-source/interfaces';
import { CampaignInventorySource } from './campaign-inventory-source/campaign-inventory-source.service';
import { ThirdPartyTechnologyPartner } from '../third-party-technology-partner/third-party-technology-partner.entity';
import { IDoohPublisher } from '../dooh_publisher/interfaces';
import { IDoohChannel } from '../dooh_channel/interfaces';
import { IDoohNetwork } from '../dooh_network/interfaces';
import { IDoohScreen } from '../dooh_screen/interfaces';
import { DoohPublisher } from '../dooh_publisher/dooh-publisher.service';
import { DoohChannel } from '../dooh_channel/dooh-channel.service';
import { DoohNetwork } from '../dooh_network/dooh-network.service';
import { DoohScreen } from '../dooh_screen/dooh-screen.service';
import { User } from '../user/user.entity';
import { IUser } from '../user/interfaces';
import { TemporalTargeting } from '../targeting-default/interfaces';
import { IGoogleBrowser, IGoogleDeviceCategory, IGoogleOperatingSystem,
    IOperatingSystem, IThirdPartyTechnologyPartner
} from '..';
import { GoogleBrowser } from '../google-browser/google_browser.service';
import { GoogleOperatingSystem } from '../google-operating-system/operating-system.service';
import { GoogleDeviceCategory } from '../google-device-categories/google-device-category.service';
import { GoogleProgrammaticInventory } from '../google-programmatic-inventory/google-programmatic-inventory.service';
import { IGoogleProgrammaticInventory } from '../google-programmatic-inventory/interfaces';

export class Campaign extends BaseEntity<ICampaign> implements ICampaign {

    public static readonly type: string = 'campaign';

    @Field()
    active: boolean;

    @Field()
    activated_at: string;

    @Field()
    ads_txt?: AdsTxt[];

    @Field()
    agency_fee_percentage: number;

    @Field()
    app_bundle_filter: string;

    @Field()
    app_bundle_targeting: string;

    @Field()
    archived: boolean;

    @Field()
    booked_budget: number;

    @Field()
    city_filter: string;

    @Field()
    clicks: number;

    @Field()
    connection_type_targeting: ConnectionTypeTargeting[];

    @Field()
    cookie_compliancy: boolean;

    @Field()
    rewarded_video: boolean;

    @Field()
    alert?: string;

    @Field()
    country_filter: string;

    @Field()
    custom_id: string;

    @Field()
    ctr: number;

    @Field()
    custom_user_rating_value: number;

    @Field()
    current_value_for_target: number;

    @Field()
    daily_cap: number;

    @Field()
    daily_cap_before_fees: number;

    @Field()
    default_display_visibility: DisplayVisibility;

    @Field()
    default_video_visibility: VideoVisibility;

    @Field()
    delivery_target: number;

    @Field()
    delivery_type: string;

    @Field()
    designated_market_area_filter: string;

    @Field()
    domain_filter: string;

    @Field()
    ecpa: number;

    @Field()
    ecpc: number;

    @Field()
    ecpm: number;

    @Field()
    end_on: string;

    @Field()
    external_purchase_order_number: string;

    @Field()
    favorite: boolean;

    @Field()
    lat_long_only?: boolean;

    @Field()
    frequency_cap: number;

    @Field()
    frequency_cap_count: number;

    @Field()
    frequency_cap_type: string;

    @Field()
    creative_sequence_attributes: { phases: Phases[] };

    @Field()
    secondary_frequency_cap: number;

    @Field()
    secondary_frequency_cap_type: string;

    @Field()
    secondary_frequency_cap_count: number;

    @Field()
    goal: string;

    @Field()
    hyperlocal_targeting: boolean;

    @Field()
    cpa_goal: number;

    @Field()
    hours?: number[];

    @Field({fromApi: DefaultTransforms.ToTemporalTargeting})
    temporal_targeting?: TemporalTargeting;

    @Field()
    id: string;

    @Field()
    impressions: number;

    @Field()
    impressions_over_margin_enabled: boolean;

    @Field()
    internal_purchase_order_number: string;

    @Field()
    interstitial: boolean;

    @Field()
    invoice_name: string;

    @Field()
    lat_long_targeting?: LatLongTargeting[];

    @Field()
    leading_invoice: string;

    @Field()
    lifetime_spent: number;

    @Field()
    total_lifetime_spent: number;

    @Field()
    total_life_spent_yesterday: number;

    @Field()
    remaining_booked_budget_yesterday: number;

    @Field()
    managed: boolean;

    @Field()
    margin: number;

    @Field()
    optimized_margin_fee_percentage?: number;

    @Field()
    media_budget: number;

    @Field()
    meetrics_reporting: boolean;

    @Field()
    minimum_ctr_percentage: number;

    @Field()
    minimum_time_in_view: number;

    @Field()
    minimum_visible_area: number;

    @Field()
    mobile_app_filter: string;

    @Field()
    mobile_apps_filter: string;

    @Field()
    must_deliver: boolean;

    @Field()
    name: string;

    @Field()
    override_rtb_price: boolean;

    @Field()
    optimizing_conversion_pixel_id: string;

    @Field()
    optimizing_pixel_editable: boolean;

    @Field()
    pace_end_on: string;

    @Field()
    pacing: string;

    @Field()
    google_inventory_source_type: string;

    @Field()
    google_urgency_level: string;

    @Field()
    google_display_creative_type: string;

    @Field()
    google_rotate_creative_type: string;

    @Field()
    platform161_tech_fee: number;

    @Field()
    postal_code_filter: string;

    @Field()
    postal_code_targeting: string;

    @Field()
    price: number;

    @Field()
    pricing_type: string;

    @Field()
    region_filter: string;

    @Field()
    rtb_domain_filter: string;

    @Field()
    rtb_domain_sets_filter: string;

    @Field()
    rtb_optimize_conversion_id: string;

    @Field()
    rtb_optimize_type?: string;

    @Field()
    rtb_price: number;

    @Field()
    section_filter: string;

    @Field()
    start_on: string;

    @Field()
    strategy_units: number;

    @Field()
    supply_types?: SupplyType[];

    @Field()
    target_type: string;

    @Field()
    target_value: number;

    @Field()
    targeting_logic: any;

    @Field()
    timing_algorithm: string;

    @Field()
    udid_mobile_targeting: string;

    @Field()
    udid_mobile_targeting_filter: string;

    @Field()
    unlimited_budget: boolean;

    @Field()
    use_platform161_tech_fee: boolean;

    @Field()
    visibility_targeting: any[];

    @Field()
    weekdays?: number[];

    @Field()
    use_targeting_sets_in_behavioural_targeting?: boolean;

    @Field()
    use_postal_codes_in_behavioural_targeting?: boolean;

    @Field()
    mobile_carrier_filter: Filter;

    @Field()
    isp_domain_filter: Filter;

    @Field()
    vpaid_targeting?: string;

    @Field()
    secure_traffic_targeting?: string;

    @Field()
    custom_user_rating?: CustomUserRating;

    @Field()
    appnexus_segment_id_targeting?: string;

    @Field()
    player_size_targeting?: string[];

    @Field()
    strategy_card?: string;

    @Field()
    strategy_tctr_percentage?: number;

    @Field()
    external_id?: string;

    @Field()
    impressions_yesterday?: number;

    @Field()
    remaining_budget?: number;

    @Field()
    gross_revenues?: number;

    @Field()
    optimal_spend_yesterday: number;

    @Field()
    optimal_spend_today: number;

    @Field()
    under_over_delivery: number;

    @Field()
    projected_spend: number;

    @Field()
    remaining_media_budget?: number;

    @Field()
    remaining_booked_budget?: number;

    @Field()
    total_cost_ecpm: number;

    @Field()
    total_cost_ecpa: number;

    @Field()
    total_cost_ecpc: number;

    @Field()
    total_optimal_spend_yesterday: number;

    @Field()
    total_spend_yesterday: number;

    @Field()
    spend_yesterday: number;

    @Field()
    conversions: number;

    @Field()
    on_time_percentage_yesterday: number;

    @Field()
    flight_on_time_percentage: number;

    @Field()
    total_on_time_percentage_yesterday: number;

    @Field()
    strategy_pc_pv_conversion?: number;

    @Field()
    net_price: number;

    @Field()
    total_campaign_cost: number;

    @Field()
    total_life_spent: number;

    @Field()
    video_completion_goal: number;

    @Field()
    video_completion_goal_percentage: number;

    @Field()
    has_video_creatives: boolean;

    @Field()
    dooh_publisher_filter?: Filter;

    @Field()
    dooh_channel_filter?: Filter;

    @Field()
    dooh_network_filter?: Filter;

    @Field()
    dooh_screen_filter?: Filter;

    @Field()
    app_targeting_sets_filter: Filter;

    @Field()
    start_on_date?: string;

    @Field()
    start_on_hour?: string;

    @Field()
    start_on_min?: string;

    @Field()
    end_on_date?: string;

    @Field()
    end_on_hour?: string;

    @Field()
    end_on_min?: string;

    @Field()
    google_campaign_status?: string;

    @Field()
    google_campaign_status_message?: string;

    @Field()
    google_campaign_id?: string;

    @Field()
    video_placement_type: VideoPlacementTargeting[];

    @Field()
    video_ad_format_type: VideoAdFormat[];

    @Field()
    open_auction_allowed: boolean;

    @Field()
    total_campaign_budget: number;

    @Field()
    screens_delivering: number;

    @Field()
    campaign_conversion_pixels_attributes: CampaignConversionPixelAttribute[];

    @Field()
    min_cpm: number;

    @Field()
    max_cpm: number;

    @Field()
    budget_as_first_goal: boolean;

    @Field()
    max_bid: number;

    @Field()
    advertiser_bundle?: string;

    @Field()
    boostr_rate?: number;

    @Field()
    boostr_line_budget_booked?: number;

    @Field()
    package_id?: string;

    @Field()
    conversion_events?: ConversionEvents;

    @Field()
    footfall_attribution?: boolean;

    @Field()
    points_of_interest?: string[];

    @Field()
    attention?: Attention[];

    @Field()
    days_to_go?: number;

    @BelongsTo({ deferredConstructor: () => Advertiser })
    advertiser: IAdvertiser | IResource;

    @BelongsTo({ deferredConstructor: () => User, apiRelationshipName: 'campaign_manager' })
    campaign_manager: IUser | IResource;

    @BelongsTo({ deferredConstructor: () => User, apiRelationshipName: 'sales_manager' })
    sales_manager: IUser | IResource;

    @HasMany({ deferredConstructor: () => CampaignConversionPixel })
    campaign_conversion_pixels: ICampaignConversionPixel[] | IResource[];

    @HasMany({ deferredConstructor: () => City, apiRelationshipName: 'cities' })
    cities: ICity[] | IResource[];

    @HasMany({ deferredConstructor: () => ConversionPixel })
    conversion_pixels: IConversionPixel[] | IResource[];

    @HasMany({ deferredConstructor: () => Country })
    countries: ICountry[] | IResource[];

    @HasMany({ deferredConstructor: () => RtbProduct })
    rtb_products: IRtbProduct[] | IResource[];

    @HasMany({ deferredConstructor: () => RtbUrl })
    rtb_urls: IRtbUrl[] | IResource[];

    @HasMany({ deferredConstructor: () => Creative })
    creatives: ICreative[] | IResource[];

    @HasMany({ deferredConstructor: () => Device, apiRelationshipName: 'devices' })
    devices: IDevice[] | IResource[];

    @HasMany({ deferredConstructor: () => DeviceType, apiRelationshipName: 'device_types' })
    device_types: IDeviceType[] | IResource[];

    @HasMany({ deferredConstructor: () => Browser, apiRelationshipName: 'browsers' })
    browsers: IBrowser[] | IResource[];

    @HasMany({ deferredConstructor: () => GoogleBrowser, apiRelationshipName: 'google_browsers' })
    google_browsers: IGoogleBrowser[] | IResource[];

    @HasMany({ deferredConstructor: () => GoogleDeviceCategory, apiRelationshipName: 'google_device_categories' })
    google_device_categories: IGoogleDeviceCategory[] | IResource[];

    @HasMany({ deferredConstructor: () => OperatingSystem, apiRelationshipName: 'operating_systems' })
    operating_systems: IOperatingSystem[] | IResource[];

    @HasMany({ deferredConstructor: () => GoogleOperatingSystem, apiRelationshipName: 'google_operating_systems' })
    google_operating_systems: IGoogleOperatingSystem[] | IResource[];

    @BelongsTo({
        deferredConstructor: () => GoogleProgrammaticInventory,
        apiRelationshipName: 'google_programmatic_inventory'
    })
    google_programmatic_inventory: IGoogleProgrammaticInventory | IResource;

    @HasMany({ deferredConstructor: () => IabCategory, apiRelationshipName: 'iab_categories' })
    iab_categories: IIabCategory[] | IResource[];

    @HasMany({ deferredConstructor: () => InventorySource })
    inventory_sources: InventorySource[] | IResource[];

    @BelongsTo({ deferredConstructor: () => MediaPlan, apiRelationshipName: 'media_plan' })
    media_plan: IMediaPlan | IResource;

    @HasMany({ deferredConstructor: () => Region, apiRelationshipName: 'regions' })
    regions: IRegion[] | IResource[];

    @HasMany({ deferredConstructor: () => Domain })
    rtb_domains: IDomain[] | IResource[];

    @HasMany({ deferredConstructor: () => DomainSet })
    rtb_domain_sets: IDomainSet[] | IResource[];

    @HasMany({ deferredConstructor: () => Language })
    languages: ILanguage[] | IResource[];

    @HasMany({ deferredConstructor: () => DirectDeal, apiRelationshipName: 'direct_deals' })
    direct_deals: IDirectDeal[] | IResource[];

    @HasMany({ deferredConstructor: () => MobileCarrier })
    mobile_carriers: IMobileCarrier[] | IResource[];

    @HasMany({ deferredConstructor: () => Domain })
    isp_domains: IDomain[] | IResource[];

    @HasMany({ deferredConstructor: () => TargetingSet })
    isp_targeting_sets: ITargetingSet[] | IResource[];

    @HasMany({ deferredConstructor: () => TargetingSet })
    targeting_sets: ITargetingSet[] | IResource[];

    @HasMany({ deferredConstructor: () => TargetingSet })
    geo_targeting_sets: ITargetingSet[] | IResource[];

    @HasMany({ deferredConstructor: () => TargetingSet })
    app_targeting_sets: ITargetingSet[] | IResource[];

    @HasMany({ deferredConstructor: () => TargetingSet })
    app_bundle_sets: ITargetingSet[] | IResource[];

    @HasMany({ deferredConstructor: () => MobileApp })
    mobile_apps: IMobileApp[] | IResource[];

    @HasMany({ deferredConstructor: () => Section })
    filtered_sections: ISection[] | IResource[];

    @HasMany({ deferredConstructor: () => Domain })
    filtered_domains: IDomain[] | IResource[];

    @HasMany({ deferredConstructor: () => GoogleVertical })
    google_verticals: IGoogleVertical[] | IResource[];

    @HasMany({ deferredConstructor: () => ContentCategory })
    content_categories: IContentCategory[] | IResource[];

    @HasMany({ deferredConstructor: () => DesignatedMarketArea })
    designated_market_areas: IDesignatedMarketArea[] | IResource[];

    @HasMany({ deferredConstructor: () => CampaignInternetServiceProvider })
    campaign_internet_service_providers: ICampaignInternetServiceProvider[] | IResource[];

    @HasMany({ deferredConstructor: () => CampaignInventorySource })
    campaign_inventory_sources: ICampaignInventorySource[] | IResource[];

    @HasMany({ deferredConstructor: () => ThirdPartyTechnologyPartner })
    thirdparty_technology_partners: IThirdPartyTechnologyPartner[] | IResource[];

    @HasMany({ deferredConstructor: () => DoohPublisher })
    dooh_publishers?: IDoohPublisher[] | IResource[];

    @HasMany({ deferredConstructor: () => DoohChannel })
    dooh_channels?: IDoohChannel[] | IResource[];

    @HasMany({ deferredConstructor: () => DoohNetwork })
    dooh_networks?: IDoohNetwork[] | IResource[];

    @HasMany({ deferredConstructor: () => DoohScreen })
    dooh_screens?: IDoohScreen[] | IResource[];

    @HasMany({ deferredConstructor: () => CampaignsDirectDeal })
    campaigns_direct_deals: ICampaignsDirectDeal[] | IResource[];

    public isOutOfHome(): boolean {
        return this.goal === 'out_of_home';
    }
}
