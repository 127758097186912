import { IActivable } from '../common/activable/interfaces';
import { IAdvertiser } from '../advertiser/interfaces';
import { IArchivable } from '../common/archivable/interfaces';
import { IBrowser } from '../browser/interfaces';
import { ICampaignsDirectDeal } from '../campaigns-direct-deal/interfaces';
import { ICity } from '../city/interfaces';
import { IContentCategory } from '../content-category/interfaces';
import { IConversionPixel } from '../conversion-pixel/interfaces';
import { ICountry } from '../country/interfaces';
import { ICreative } from '../creative/interfaces';
import { IDevice } from '../device/interfaces';
import { IDeviceType } from '../device-type/interfaces';
import { IDomain } from '../domain/interfaces';
import { IDuplicable } from '../common/duplicable/interfaces';
import { IGoogleVertical } from '../google-vertical/interfaces';
import { IIabCategory } from '../iab-category/interfaces';
import { IMediaPlan } from '../media-plan/interfaces';
import { IMobileCarrier } from '../mobile-carrier/interfaces';
import { IOperatingSystem } from '../operating-system/interfaces';
import { IPersistentApiClient, ICollection, IResource } from '../common';
import { IRegion } from '../region/interfaces';
import { IRtbUrl } from '../rtb-url/interfaces';
import { ICampaignConversionPixel } from '../campaign-conversion-pixel/interfaces';
import { IDesignatedMarketArea } from '../designated-market-area/interfaces';
import { ICampaignInventorySource } from './campaign-inventory-source/interfaces';
import { IFavoriteable } from '../common/favoriteable/interfaces';
import { IInventory } from '../common/inventory';
import { IGoogleBrowser, IGoogleDeviceCategory, IGoogleOperatingSystem, IThirdPartyTechnologyPartner } from '../';
import { IDoohPublisher } from '../dooh_publisher/interfaces';
import { IDoohChannel } from '../dooh_channel/interfaces';
import { IDoohNetwork } from '../dooh_network/interfaces';
import { IDoohScreen } from '../dooh_screen/interfaces';
import { ITargetingSet } from '../targeting-set/interfaces';
import { ILanguageAttribute } from '../common/language';
import { IBehavioralAttribute } from '../common/behavioral';
import { IUser } from '../user/interfaces';
import { TemporalTargeting } from '../targeting-default/interfaces';
import { IGoogleProgrammaticInventory } from '../google-programmatic-inventory/interfaces';

export interface ICampaignMail extends ILanguageAttribute, IBehavioralAttribute {
    name: string;
    impressions: number;
    price: number;
    media_budget: number;
    booked_budget: number;
    start_on: string;
    end_on: string;
    isp_domain_filter: Filter;
    weekdays?: number[];
    hours?: number[];
    temporal_targeting?: TemporalTargeting;
    vpaid_targeting?: string;
    player_size_targeting?: string[];
    secure_traffic_targeting?: string;
    visibility_targeting: any[];
    appnexus_segment_id_targeting?: string;

    device_types: IDeviceType[] | IResource[];
    isp_domains: IDomain[] | IResource[];
    advertiser: IAdvertiser | IResource;
    media_plan: IMediaPlan | IResource;
    devices: IDevice[] | IResource[];
    browsers: IBrowser[] | IResource[];
    operating_systems: IOperatingSystem[] | IResource[];
    mobile_carriers: IMobileCarrier[] | IResource[];
    content_categories: IContentCategory[] | IResource[];
}

export interface ICampaign extends ICampaignMail, IInventory, IResource {
    active: boolean;
    activated_at: string;
    agency_fee_percentage?: number;
    agency_fee?: number;
    archived: boolean;
    city_filter?: string;
    custom_id?: string;
    clicks: number;
    connection_type_targeting: ConnectionTypeTargeting[];
    country_filter?: string;
    ctr: number;
    alert?: string;
    custom_user_rating_value?: number;
    current_value_for_target?: number;
    daily_cap: number;
    daily_cap_before_fees: number;
    default_display_visibility?: DisplayVisibility;
    default_video_visibility?: VideoVisibility;
    delivery_target: number;
    delivery_type: string;
    designated_market_area_filter: string;
    ecpa: number;
    ecpc: number;
    ecpm: number;
    external_purchase_order_number?: string;
    favorite: boolean;
    frequency_cap?: number;
    frequency_cap_count?: number;
    frequency_cap_type: string;
    creative_sequence_attributes: { phases: Phases[] };
    secondary_frequency_cap?: number;
    secondary_frequency_cap_type?: string;
    secondary_frequency_cap_count?: number;
    goal: string;
    hyperlocal_targeting: boolean;
    cpa_goal: number;
    impressions_over_margin_enabled: boolean;
    use_targeting_sets_in_behavioural_targeting?: boolean;
    use_postal_codes_in_behavioural_targeting?: boolean;
    internal_purchase_order_number?: string;
    invoice_name: string;
    lat_long_targeting?: LatLongTargeting[];
    leading_invoice: string;
    lifetime_spent: number;
    total_lifetime_spent: number;
    margin?: number;
    optimized_margin_fee_percentage?: number;
    meetrics_reporting: boolean;
    minimum_ctr_percentage?: number;
    minimum_time_in_view: number;
    minimum_visible_area: number;
    must_deliver: boolean;
    override_rtb_price: boolean;
    optimizing_conversion_pixel_id?: string;
    optimizing_pixel_editable?: boolean;
    lat_long_only?: boolean;
    pace_end_on: string;
    pacing: string;
    platform161_tech_fee?: number;
    platform161_tech_fee_percentage?: number; // duplication ??
    strategy_pc_pv_conversion?: number;
    strategy_card?: string;
    postal_code_filter: string;
    postal_code_targeting?: string;
    region_filter?: string;
    rtb_optimize_conversion_id?: string;
    rtb_optimize_type?: string;
    rtb_price: number;
    strategy_units: number;
    target_type: string;
    target_value: number;
    timing_algorithm: string;
    udid_mobile_targeting: string;
    udid_mobile_targeting_filter: string;
    unlimited_budget: boolean;
    use_platform161_tech_fee?: boolean;
    mobile_carrier_filter: Filter;
    custom_user_rating?: CustomUserRating;
    strategy_tctr_percentage?: number;
    external_id?: string;
    impressions_yesterday?: number;
    remaining_budget?: number; // this is instance.cost? ? remaining_budget : remaining_media_budget
    gross_revenues?: number;
    optimal_spend_yesterday: number;
    optimal_spend_today: number;
    under_over_delivery: number;
    projected_spend: number;
    remaining_media_budget?: number;
    remaining_booked_budget?: number;
    total_cost_ecpm: number;
    total_cost_ecpa: number;
    total_cost_ecpc: number;
    total_optimal_spend_yesterday: number;
    total_spend_yesterday: number;
    total_on_time_percentage_yesterday: number;
    total_campaign_cost: number;
    total_life_spent: number;
    total_life_spent_yesterday: number;
    remaining_booked_budget_yesterday: number;
    conversions: number;
    flight_on_time_percentage: number;
    net_price: number;
    spend_yesterday: number;
    on_time_percentage_yesterday: number;
    video_completion_goal?: number;
    video_completion_goal_percentage?: number;
    has_video_creatives: boolean;
    active_visibility?: boolean; // @todo not implemented in API
    dooh_publisher_filter?: Filter;
    dooh_channel_filter?: Filter;
    dooh_network_filter?: Filter;
    dooh_screen_filter?: Filter;
    google_inventory_source_type?: string;
    google_urgency_level?: string;
    google_display_creative_type?: string;
    google_rotate_creative_type?: string;
    app_targeting_sets_filter: string;
    google_campaign_status?: string;
    google_campaign_status_message?: string;
    google_campaign_id?: string;
    advertiser_bundle?: string;
    days_to_go?: number;
    targeting_sets: ITargetingSet[] | IResource[];
    app_targeting_sets: ITargetingSet[] | IResource[];
    app_bundle_sets: ITargetingSet[] | IResource[];
    isp_targeting_sets: ITargetingSet[] | IResource[];
    geo_targeting_sets: ITargetingSet[] | IResource[];
    creatives: ICreative[] | IResource[];
    sales_manager?: IUser | IResource;
    campaign_manager?: IUser | IResource;
    campaign_conversion_pixels: ICampaignConversionPixel[] | IResource[];
    cities: ICity[] | IResource[];
    conversion_pixels: IConversionPixel[] | IResource[];
    countries: ICountry[] | IResource[];
    google_verticals: IGoogleVertical[] | IResource[];
    iab_categories: IIabCategory[] | IResource[];
    regions: IRegion[] | IResource[];
    rtb_urls: IRtbUrl[] | IResource[];
    designated_market_areas: IDesignatedMarketArea[] | IResource[];
    campaign_inventory_sources: ICampaignInventorySource[] | IResource[];
    thirdparty_technology_partners: IThirdPartyTechnologyPartner[] | IResource[];
    dooh_publishers?: IDoohPublisher[] | IResource[];
    dooh_channels?: IDoohChannel[] | IResource[];
    dooh_networks?: IDoohNetwork[] | IResource[];
    dooh_screens?: IDoohScreen[] | IResource[];
    screens_delivering: number;
    google_browsers: IGoogleBrowser[] | IResource[];
    google_device_categories?: IGoogleDeviceCategory[] | IResource[];
    google_operating_systems: IGoogleOperatingSystem[] | IResource[];
    google_programmatic_inventory: IGoogleProgrammaticInventory | IResource;
    video_placement_type: VideoPlacementTargeting[];
    video_ad_format_type: VideoAdFormat[];
    campaigns_direct_deals: ICampaignsDirectDeal[] | IResource[];
    open_auction_allowed: boolean;
    total_campaign_budget: number;
    campaign_conversion_pixels_attributes: CampaignConversionPixelAttribute[];
    min_cpm: number;
    max_cpm: number;
    budget_as_first_goal: boolean;
    max_bid: number;
    boostr_rate?: number;
    boostr_line_budget_booked?: number;
    package_id?: string;
    conversion_events?: ConversionEvents;
    footfall_attribution?: boolean;
    points_of_interest?: string[];
    attention?: Attention[];
}

export type Bv4BookedBudget = {
    id: string;
    pub_payout: number;
    units_spent: number;
    created_at?: number;
};

export type ConnectionTypeTargeting = 'wifi' | 'ethernet' | 'cellular' | 'unknown';
export type Filter = 'include' | 'exclude';
export type DisplayVisibility = 'ias';
export type VideoVisibility = 'ias' | 'meetrics' | 'moat';
export type AdsTxt = 'unknown' | 'direct' | 'reseller' | 'unauthorized';
export type SupplyType = 'in_app' | 'browser' | 'mobile_optimised';
export type VideoPlacementTargeting = '1' | '2' | '3' | '4' | '5';
export type VideoAdFormat = 'pre_roll' | 'mid_roll' | 'post_roll';
export type Attention = 'low' | 'medium' | 'high';
export type CustomUserRating = {
    rating?: string;
    min_rating?: number;
    max_rating?: number;
};

export type LatLongTargeting = {
    name: string;
    lat: number;
    long: number;
    radius: number;
};

export type Phases = {
    creative_ids: string[];
    count: string;
};

export type CampaignConversionPixelAttribute = {
    conversion_pixel_id: string;
    post_view_price: number;
    post_click_price: number;
    gross_price: number;
};

export type ConversionEvents = {
   install?: number;
   registration?: number;
   first_deposit?: number;
   deposit?: number;
   first_purchase?: number;
   purchase?: number;
   level_x_passed?: number;
   other?: number;
   other2?: number;
};

export type IProcessScreens = 'activate' | 'deactivate';

export type ICampaignCollection = ICollection<ICampaign>;

export type CampaignBelongsToRelationships = never;
export type CampaignHasManyRelationships = never;

export interface ICampaignClient
    extends IPersistentApiClient<ICampaign, CampaignBelongsToRelationships, CampaignHasManyRelationships>,
    IArchivable<ICampaign>,
    IActivable<ICampaign>,
    IFavoriteable<ICampaign>,
    IDuplicable<ICampaign> { }
